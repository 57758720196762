import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Description from '@mui/icons-material/Description';
import PeopleIcon from '@mui/icons-material/People';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { Link, useLocation } from 'react-router-dom';

interface Props {
  setMobileOpen?: (open: boolean) => void;
}

/**
 * メニュー部のJSX
 *
 * @param param0
 * @returns
 */
const Menu = ({ setMobileOpen }: Props) => {
  const doNothing = (open: boolean) => {};
  const setOpen = setMobileOpen ?? doNothing;

  const location = useLocation();

  const menus = [
    {
      text: 'ダッシュボード',
      pathname: '/',
      icon: QueryStatsIcon,
      testid: 'dashboard',
    },
    {
      text: 'クリエイター申請',
      pathname: '/applyCreator',
      icon: HistoryEduIcon,
      testid: 'applyCreator',
    },
    {
      text: 'クリエイター一覧',
      pathname: '/creatorList',
      icon: PeopleIcon,
      testid: 'creatorList',
    },
    {
      text: '支払いレポート',
      pathname: '/paymentReport',
      icon: Description,
      testid: 'paymentReport',
    },
  ];

  return (
    <div>
      <Divider />
      <List>
        {menus.map((menu: any) => (
          <ListItemButton
            key={menu.text}
            component={Link}
            to={menu.pathname}
            selected={location.pathname === menu.pathname}
            onClick={() => setOpen(false)}
            data-testid={menu.testid}
          >
            <ListItemIcon sx={{ minWidth: 40 }}>
              <menu.icon />
            </ListItemIcon>
            <ListItemText primary={menu.text} />
          </ListItemButton>
        ))}
        <Divider />
        <span onClick={() => setOpen(false)}>
          <ListItemButton
            key="account"
            component={Link}
            to={'/account'}
            selected={location.pathname === '/account' || location.pathname === '/accountEdit'}
            data-testid="account"
          >
            <ListItemIcon sx={{ minWidth: 40 }}>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary="アカウント設定" />
          </ListItemButton>
        </span>
      </List>
    </div>
  );
};

export default Menu;
