import {
  CircularProgress,
  Grid2 as Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import ScoreType from '../../types/ScoreType';
import axios from 'axios';
import { useParams } from 'react-router-dom';

/**
 * 配信成績画面
 * @returns 配信成績のJSX
 */
const Score = () => {
  const [score, setScore] = useState<ScoreType | null>(null);
  const params = useParams();

  /**
   * 配信時間（秒）を時刻形式に変換する
   *
   * @param seconds
   * @returns
   */
  function secToHMS(seconds: number) {
    const hour = Math.floor(seconds / 3600);
    const min = Math.floor((seconds % 3600) / 60);
    const sec = seconds % 60;
    let hh;
    // hour が3桁以上の場合は左0埋めをしない
    if (hour < 100) {
      hh = `00${hour}`.slice(-2);
    } else {
      hh = hour;
    }
    const mm = `00${min}`.slice(-2);
    const ss = `00${sec}`.slice(-2);
    const time = `${hh}:${mm}:${ss}`;
    return time;
  }

  /**
   * 初期処理　配信実績を取得する
   */
  useEffect(() => {
    const api_base_url = process.env.REACT_APP_PROXY_BASE_URL ?? 'http://localhost:3000';
    const param = {
      data: {
        creator_id: params.creator_id,
        channel_id: params.channel_id,
      },
    };
    const config = {
      headers: {
        'content-type': 'application/json',
      },
    };
    axios.post(api_base_url + 'agencyPortalGetCreatorLiveScore', param, config).then((response) => {
      setScore(response.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 配信実績が取れていない段階はロード中を表示
  if (score == null) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      <h2>{score.channel_title}</h2>
      <Table sx={{ maxWidth: 450 }} aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              開始時間
            </TableCell>
            <TableCell align="right">{new Date(score.channel_start_at).toLocaleString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              配信時間
            </TableCell>
            <TableCell align="right">{secToHMS(score.elapsed_time_seconds)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              ハート数
            </TableCell>
            <TableCell align="right">{score.total_hearts_num.toLocaleString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              コメント数
            </TableCell>
            <TableCell align="right">{score.total_comments_num.toLocaleString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              ギフト数
            </TableCell>
            <TableCell align="right">{score.total_gifts_num.toLocaleString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              最大同時視聴人数
            </TableCell>
            <TableCell align="right">
              {score.max_concurrent_audience_users_num.toLocaleString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              トータル視聴人数
            </TableCell>
            <TableCell align="right">{score.total_audience_users_num.toLocaleString()}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default Score;
