import './App.css';
import { Routes, Route } from 'react-router-dom';
import {
  Dashboard,
  Account,
  PasswordEdit,
  Achievements,
  ApplyCreator,
  CreatorList,
  Score,
  PaymentReport,
} from './components/index';

const MainPageRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} /> {/* ダッシュボード */}
      <Route path="/applyCreator" element={<ApplyCreator />} /> {/* クリエイター申請に遷移 */}
      <Route path="/creatorList" element={<CreatorList />} /> {/* クリエイター一覧 */}
      <Route path="/achievements/:creator_id" element={<Achievements />} /> {/* 配信実績確認 */}
      <Route path="/score/:creator_id/:channel_id" element={<Score />} /> {/* 成績確認 */}
      <Route path="/PaymentReport" element={<PaymentReport />} /> {/* 支払いレポート */}
      <Route path="/account" element={<Account />} /> {/* アカウント設定 */}
      <Route path="/passwordEdit" element={<PasswordEdit />} /> {/* パスワード変更 */}
    </Routes>
  );
};

export default MainPageRoutes;
