import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';
import { Route, Routes } from 'react-router-dom';
import { useAuthContext } from '../../providers/AuthContext';

const drawerWidth = 240;

interface Props {
  handleDrawerToggle: () => void;
  openMenu: boolean;
}
/**
 * ヘッダー部のJSX
 *
 * @param param0
 * @returns
 */
const Header = ({ handleDrawerToggle, openMenu }: Props) => {
  const { logout } = useAuthContext();

  async function handleLogout() {
    try {
      await logout();
      window.location.href = '/';
    } catch {
      console.log('ログアウト失敗');
    }
  }
  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          bgcolor: 'black',
          width: { sm: openMenu ? `calc(100% - ${drawerWidth}px)` : `calc(100%)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={openMenu ? { mr: 2, display: { sm: 'none' } } : { mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            data-testid="title"
            variant="h6"
            style={{ textDecoration: 'none' }}
            color="inherit"
            noWrap
          >
            <Routes>
              <Route path="/" element={'ダッシュボード'} /> {/* ダッシュボード */}
              <Route path="/applyCreator" element={'クリエイター申請'} />{' '}
              {/* クリエイター申請に遷移 */}
              <Route path="/creatorList" element={'クリエイター一覧'} /> {/* クリエイター一覧 */}
              <Route path="/achievements/*" element={'配信実績確認'} /> {/* 配信実績確認 */}
              <Route path="/score/*" element={'成績確認'} /> {/* 成績確認 */}
              <Route path="/paymentReport" element={'支払いレポート'} /> {/* 支払いレポート */}
              <Route path="/account" element={'アカウント情報'} /> {/* アカウント情報 */}
              <Route path="/passwordEdit" element={'パスワード変更'} /> {/* パスワード変更 */}
            </Routes>
          </Typography>
          <div style={{ flexGrow: 1 }}></div>
          <IconButton sx={{ ml: 1 }} onClick={handleLogout} color="inherit">
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
