import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Description from '@mui/icons-material/Description';
import PeopleIcon from '@mui/icons-material/People';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

interface Props {
  setMobileOpen?: (open: boolean) => void;
}

/**
 * ログイン時の読み込み途中にダミーで出すメニュー部のJSX
 *
 * @param param0
 * @returns
 */
const DummyMenu = ({ setMobileOpen }: Props) => {
  const doNothing = (open: boolean) => {};
  const setOpen = setMobileOpen ?? doNothing;
  const menus = [
    {
      text: 'ダッシュボード',
      pathname: '/',
      icon: QueryStatsIcon,
      testid: 'dashboard',
    },
    {
      text: 'クリエイター申請',
      pathname: '/applyCreator',
      icon: HistoryEduIcon,
      testid: 'applyCreator',
    },
    {
      text: 'クリエイター一覧',
      pathname: '/creatorList',
      icon: PeopleIcon,
      testid: 'creatorList',
    },
    {
      text: '支払いレポート',
      pathname: '/paymentReport',
      icon: Description,
      testid: 'paymentReport',
    },
  ];

  return (
    <div>
      <Divider />
      <List>
        {menus.map((menu: any) => (
          <ListItemButton key={menu.text} onClick={() => setOpen(false)} data-testid={menu.testid}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <menu.icon />
            </ListItemIcon>
            <ListItemText primary={menu.text} />
          </ListItemButton>
        ))}
        <Divider />
        <span onClick={() => setOpen(false)}>
          <ListItemButton key="account" data-testid="account">
            <ListItemIcon sx={{ minWidth: 40 }}>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary="アカウント設定" />
          </ListItemButton>
        </span>
      </List>
    </div>
  );
};

export default DummyMenu;
