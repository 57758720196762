import {
  Button,
  CircularProgress,
  Divider,
  Grid2 as Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import InfoIcon from '@mui/icons-material/Info';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import NewsType from '../../types/NewsType';
import SimpleReportType from '../../types/SimpleReportType';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

/**
 * ダッシュボード画面
 * @returns ダッシュボードのJSX
 */
const Dashboard = () => {
  const [simpleReport, setSimpleReport] = useState<SimpleReportType | null>(null);
  const [news, setNews] = useState<Array<NewsType> | null>(null);

  /**
   * 初期処理
   */
  useEffect(() => {
    const api_base_url = process.env.REACT_APP_PROXY_BASE_URL ?? 'http://localhost:3000/';
    // 簡易レポートを取得
    axios.get(api_base_url + 'agencyPortalGetSimpleReport').then((response) => {
      setSimpleReport(response.data);
    });
    // お知らせを取得
    axios.get(api_base_url + 'agencyPortalGetNewslist').then((response) => {
      setNews(response.data);
    });
  }, []);

  // データが取れる前は処理中のアイコンを表示
  if (simpleReport == null || news == null) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid size={{ xs: 4, sm: 8, md: 6 }}>
            <Item
              sx={{
                p: 2,
              }}
            >
              <Grid
                container
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="subtitle1" gutterBottom align="left">
                  <QueryStatsIcon fontSize="small" style={{ verticalAlign: -3 }} />
                  簡易レポート
                </Typography>

                <Typography variant="caption" align="right">
                  累計報酬額には当日分の速報値を含んでいます
                </Typography>
              </Grid>
              <Grid container p={1}>
                <Grid size={6} p={1}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    align="center"
                    sx={{ fontWeight: 'bold' }}
                  >
                    今月の累計報酬額
                  </Typography>

                  <Typography variant="subtitle2" gutterBottom align="right">
                    {simpleReport.pay_for_performance.this_month.toLocaleString()}円
                  </Typography>
                </Grid>
                <Grid size={6} p={1}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    align="center"
                    sx={{ fontWeight: 'bold' }}
                  >
                    いままでの累計報酬額
                  </Typography>

                  <Typography variant="subtitle2" gutterBottom align="right">
                    {simpleReport.pay_for_performance.total.toLocaleString()}円
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid size={{ xs: 4, sm: 8, md: 12 }} textAlign={'right'} padding={1}>
                <Button
                  variant="contained"
                  size="small"
                  component={RouterLink}
                  to={'/creatorList'}
                  sx={{ bgcolor: '#FF4455' }}
                >
                  詳細レポート
                </Button>
              </Grid>
            </Item>
          </Grid>
          <Grid size={{ xs: 4, sm: 8, md: 6 }}>
            <Item
              sx={{
                p: 2,
              }}
            >
              <Typography variant="subtitle1" align="left">
                <InfoIcon fontSize="small" style={{ verticalAlign: -3 }} />
                お知らせ
              </Typography>
              <Divider />
              <TableContainer sx={{ maxHeight: 400 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableBody>
                    {news.length > 0
                      ? news.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell
                              sx={{
                                padding: 0,
                                py: 1,
                                width: 100,
                                verticalAlign: 'top',
                                fontWeight: 'bold',
                              }}
                            >
                              {row.created_at.substring(0, 10)}
                            </TableCell>
                            <TableCell sx={{ padding: 0, paddingLeft: 1, py: 1 }} align="left">
                              <span style={{ fontWeight: 'bold' }}>{row.title}</span>
                              <br />
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: row.body,
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))
                      : 'お知らせがありません。'}
                  </TableBody>
                </Table>
              </TableContainer>
            </Item>
          </Grid>
          <Grid size={{ xs: 4, sm: 8, md: 6 }}>
            <Box>
              <Grid size={{ xs: 4, sm: 8, md: 12 }} textAlign={'right'}>
                <Typography variant="caption" align="right" fontSize={10}>
                  成功報酬：登録したクリエイターが配信するライブ配信において、視聴者からのギフト送信によって獲得した合計収益の３０％に相当する金額
                </Typography>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Dashboard;
