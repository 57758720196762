import LiveHistoryType from './LiveHistoryType';

class AchievementType {
  total_days_num: number; //配信実績日数
  total_revenue: number; //収益
  total_time_seconds: number; //配信時間
  valid_total_days_num: number; //有効配信日数
  valid_total_time_seconds: number; //有効配信時間
  live_history: Array<LiveHistoryType>; // 実績
  constructor() {
    this.total_days_num = 0;
    this.total_revenue = 0;
    this.total_time_seconds = 0;
    this.valid_total_days_num = 0;
    this.valid_total_time_seconds = 0;
    this.live_history = [];
  }
}

export default AchievementType;
